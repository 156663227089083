@use "./src/scss/global.scss" as *;

.footer {
  background: $dark;
  padding-top: 100px;
  padding-bottom: 110px;
  z-index: $z-index200;

  .footer-container {
    @include flex-column(flex-start, flex-start);
    margin: auto;
    max-width: 920px;

    .footer-logo {
      transform: translateX(-8px);
    }
  }

  .links-wrapper,
  .policy-wrapper {
    @include flex-row(space-between);
    align-items: flex-start;
    width: 100%;
  }

  .links-wrapper {
    margin: 42px 0 84px;

    .footer-scroll-links {
      @include flex-column(flex-start, flex-start);
      row-gap: 23px;
    }

    .contacts-links {
      .social-media {
        width: 200px;
      }

      .contacts {
        margin-top: 42px;
        @include flex-column(flex-end, flex-start);
      }
    }
  }

  @include mq($mobile) {
    padding-bottom: 90px;

    .footer-container {
      align-items: center;
    }

    .links-wrapper,
    .policy-wrapper {
      flex-direction: column;
      align-items: center;

      .footer-scroll-links {
        align-items: center;
      }
    }

    .links-wrapper {
      margin: 30px 0 46px;

      .contacts-links {
        margin-top: 115px;

        .contacts {
          align-items: center;
          row-gap: 20px;
        }
      }
    }

    .policy {
      order: 2;
      margin-top: 100px;
    }

    .back-top-btn {
      order: 1;
    }
  }
}
