@use "./src/scss/global.scss" as *;

.certik {
  height: 100vh;

  &-container {
    @include flex-center;
    width: 100%;
  }

  &-content {
    @include flex-column(center, center);

    .hero-box {
      position: relative;

      .hero-image {
        z-index: 1;
      }

      .hero-blur {
        @include absolute-center;
        width: 275px;
        height: 157px;
        background: $shadow-violet;
        filter: blur(72.2359px);
        opacity: 0.7;
      }
    }

    .description {
      max-width: 510px;
      text-align: center;
      padding-top: 51px;
    }

    .certik-button {
      margin-top: 40px;
    }

    @include mq($mobile) {
      .description {
        max-width: 297.11px;
      }
    }
  }
}
