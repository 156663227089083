@use "./src/scss/global.scss" as *;

%transition {
  transition: all 0.3s ease-out;
}

.buff-scroll-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;
  cursor: pointer;
  @include text-scaffold(14px, $regular, $white, 24px, 0);
  @extend %transition;

  &:hover {
    color: $green;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }

  .link-left-icon {
    margin-right: 8px;
  }

  .link-right-icon {
    margin-left: 8px;
  }
}
