@use "./src/scss/global.scss" as *;

.button-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  gap: 15px;
  box-sizing: border-box;

  &.disabled {
    pointer-events: none;
  }

  &-icon {
    width: auto;
    height: max-content;

    @include mq($mobile) {
      max-width: 25px;
      max-height: 25px;
    }
  }

  @include mq($mobile) {
    gap: 10px;
  }
}

.contained-link {
  box-shadow: $shadow-dark;
  text-transform: uppercase;
  font-weight: $medium;
  font-style: normal;
  transition: background-color 0.2s ease-out;

  &.primary {
    background-color: $primary;
    color: $white;
    box-shadow: $shadow-dark;

    &:hover {
      background-color: $primary-hover;
    }
  }

  &.secondary {
    background-color: $secondary;
    color: $white;
    box-shadow: $shadow-dark;

    &:hover {
      background-color: $secondary-hover;
    }
  }

  &.size-normal {
    border-radius: 58px;
    height: 42px;
    padding: 8px 20px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  &.size-big {
    border-radius: 70px;
    height: 65px;
    padding: 0 34px;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.15px;

    @include mq($mobile) {
      height: 46.4px;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.1px;
    }
  }
}

.outlined-link {
  font-weight: $regular;
  transition: border-color 0.2s ease-out, color 0.2s ease-out;

  &.primary {
    border: 1px solid $green;
    color: $green;

    &:hover {
      border-color: $secondary-hover;
      color: $secondary-hover;
    }
  }

  &.secondary {
    border: 1px solid $black;
    color: $black;

    &:hover {
      border-color: $secondary-hover;
      color: $secondary-hover;
    }
  }

  &.third {
    border: 1.36px solid $primary;
    color: $primary;

    &:hover {
      border-color: $primary-hover;
      color: $primary-hover;
    }
  }

  &.size-normal {
    height: 25px;
    font-size: 14px;
    line-height: 24px;
    border-radius: 18.5px;
    padding: 0 15px;
  }

  &.size-big {
    height: 38px;
    font-size: 20px;
    line-height: 24px;
    border-radius: 25.16px;
    padding: 0 35px;
  }
}
