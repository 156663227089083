@use "./mixins.scss" as *;
@use "./variables.scss" as *;

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;

  font-family: "Geomanist", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html,
body,
#root,
section {
  height: max-content;
  width: 100%;
}

section {
  position: relative;
  overflow-x: hidden;
  padding: 0px 12px;
}

hr {
  margin: 0px;
  padding: 0px;
}

a {
  color: unset;
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
}

.container-xxl {
  max-width: 1440px !important;
  background-color: transparent;
  height: 100%;
  position: relative;
  padding: 0px;
}

.container-xl {
  max-width: 1100px !important;
  background-color: transparent;
  height: 100%;
  position: relative;
  padding: 0px;
}
