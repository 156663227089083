@use "./src/scss/global.scss" as *;

.app-navbar-desktop {
  position: absolute;
  left: 0;
  top: 45px;
  height: max-content;
  width: 100%;
  z-index: $z-index300;
  overflow-x: unset;
  background-color: transparent;

  .navbar-content {
    height: 100%;
    width: 100%;
    @include flex-row(space-between);
  }

  .navbar-logo {
    cursor: pointer;
  }

  &.scrolled {
    background-color: $green;
    height: 50px;
    opacity: 0.9;
    position: fixed;
    left: 0;
    top: 0;
  }
}
