@use "./src/scss/global.scss" as *;

%transition {
  transition: all 0.3s ease-out;
}

.social-icon-link {
  height: max-content;
  width: auto;
  @extend %transition;

  &:hover {
    filter: brightness(0.7);
  }
}
