@use "./src/scss/global.scss" as *;

.partnerhips-bar {
  @include flex-row(center);
  flex-wrap: wrap;
  gap: 49px;
  height: max-content;
  width: 100%;
  padding: 15.5px 15.5px 15.5px 32px;

  @include mq($mobile) {
    padding: 7.6px 7.6px 7.6px 15.7px;
    gap: 24px;
  }
}
