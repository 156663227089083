@use "./src/scss/global.scss" as *;

.buynow {
  padding: 391px 0 325px;
  background: $dark-purple1;
  overflow-x: clip;

  @include mq($mobile) {
    padding: 100px 0 423px;
  }

  &-coins-bg-left {
    position: absolute;
    top: 50%;
    left: 70px;
    transform: translateY(-50%);
    z-index: 1;
  }

  &-coins-bg-right {
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translateY(-50%);
    z-index: 1;
  }

  @include mq($tablet) {
    &-coins-bg-left {
      left: 10px;
    }

    &-coins-bg-right {
      right: 10px;
    }
  }

  &-coins-bg-mobile {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  &-content {
    @include flex-column(center, center);
    text-align: center;
    position: relative;
    z-index: 2;

    .header {
      max-width: 340px;
    }

    .description {
      max-width: 312px;
      margin-top: 43px;
    }

    .buynow-button-wrapper {
      padding-top: 63px;
      position: relative;

      .button-animation {
        position: relative;
        z-index: 3;
      }

      .button {
        width: 410px;
      }

      .button-blur {
        width: 282px;
        height: 114px;
        @include absolute-center;
        background: $shadow-purple2;
        filter: blur(135px);
        z-index: 0;
      }

      @include mq($mobile) {
        .button {
          width: 275px;
        }
      }
    }
  }
}
