@use "./src/scss/global.scss" as *;

.app-navbar-mobile {
  position: fixed;
  right: 0;
  top: 42px;
  height: max-content;
  width: max-content;
  z-index: $z-index300;
}
