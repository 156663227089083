@use "./mixins.scss" as *;
@use "./variables.scss" as *;

.text-white {
  color: $white !important;
}

.regular {
  font-weight: $regular;
}

.medium {
  font-weight: $medium;
}

.bold {
  font-weight: $bold;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.as-row {
  @include flex-row(flex-start);
}

.as-col {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}
.w-max {
  width: max-content;
}

.desktop-xs {
  display: block;

  @include mq(360px) {
    display: none;
  }
}

.mobile-xs {
  display: none;

  @include mq(360px) {
    display: block;
  }
}

.desktop-sm {
  display: block;

  @include mq(576px) {
    display: none;
  }
}

.mobile-sm {
  display: none;

  @include mq(576px) {
    display: block;
  }
}
.desktop-md {
  display: block;

  @include mq(768px) {
    display: none;
  }
}

.mobile-md {
  display: none;

  @include mq(768px) {
    display: block;
  }
}

.desktop-lg {
  display: block;

  @include mq(991px) {
    display: none;
  }
}

.mobile-lg {
  display: none;

  @include mq(991px) {
    display: block;
  }
}

.desktop-xl {
  display: block;

  @include mq(1200px) {
    display: none;
  }
}

.mobile-xl {
  display: none;

  @include mq(1200px) {
    display: block;
  }
}

.desktop-xxl {
  display: block;

  @include mq(1400px) {
    display: none;
  }
}

.mobile-xxl {
  display: none;

  @include mq(1400px) {
    display: block;
  }
}
