@use "./src/scss/global.scss" as *;

.use-case-card {
  @include flex-column(flex-start, flex-start);

  .case-title {
    padding-top: 15px;
  }

  .case-description {
    padding-top: 36px;
  }

  @include mq($mobile) {
    align-items: center;

    .case-title {
      padding-top: 14px;
    }

    .case-description {
      padding-top: 18px;
      text-align: center;
    }
  }
}
