@use "./src/scss/global.scss" as *;

.roadmap-header {
  @include flex-column(flex-end, flex-start);

  h2 {
    @include text-scaffold(60px, $bold, $white, 36px, 0);
    text-transform: uppercase;
  }
}

.roadmap-header-animation {
  animation: fadeIn 0.5s ease-in;
  will-change: opacity;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
