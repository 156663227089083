@use "./src/scss/global.scss" as *;

.roadmap-item-loader {
  img {
    animation: spin linear 1s infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
