@use "./src/scss/global.scss" as *;

.roadmap {
  background: $gradient3;
  overflow-x: clip;
  padding: 0;

  &-sticky-overlay {
    position: sticky;
    top: 0px;
    height: 100vh;

    .roadmap-lines-animation {
      position: absolute;
      top: 50%;
      bottom: 0;
      transform: perspective(60em) rotate3d(1, 0, 0, 10deg) scaleX(2) scaleY(1.3);

      svg g path {
        stroke: rgba(192, 60, 255, 0.2);
        stroke-width: 2;
      }

      @include mq($mobile) {
        top: 60%;
        transform: perspective(60em) rotate3d(1, 0, 0, 10deg) scaleX(2) scaleY(5);

        svg g path {
          stroke-width: 1.5;
        }
      }

      @media only screen and (min-width: 1920px) {
        svg g path {
          stroke-width: 1;
        }
      }
    }

    .line {
      width: max-content;
      height: 100%;
      position: absolute;
      top: 0;
      left: 20px;
    }

    @include mq($mobile) {
      .line {
        left: 27px;
        height: max-content;
      }
    }

    .roadmap-header {
      position: absolute;
      top: 120px;
      right: 100px;

      @include mq($mobile) {
        top: 140px;
        right: 16px;
      }

      @media only screen and (min-width: 1920px) {
        top: 220px;
      }
    }
  }

  &-list {
    position: relative;
    top: calc(-100vh);
    width: 100%;
    max-width: 650px;
    padding-top: 200px;
    right: 100px;
    margin-left: auto;
    @include flex-column(flex-end, flex-start);
    row-gap: 50vh;

    @include mq($mobile) {
      right: 16px;
      padding-top: 220px;
      max-width: 90%;
    }

    @media only screen and (min-width: 1920px) {
      padding-top: 300px;
    }
  }
}
