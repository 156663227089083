@use "./src/scss/global.scss" as *;

.hero {
  background: $gradient1;
  height: 100vh;

  .navbar-logo-splash {
    position: absolute;
    width: 445.05px;
    height: 180.09px;
    left: -200px;
    top: -50px;
    background: $shadow-green;
    filter: blur(214px);
    transform: rotate(-36deg);
    z-index: 0;
  }

  .sticky-social-media-splash {
    position: absolute;
    width: 445px;
    height: 180px;
    top: 289px;
    right: -245px;
    background: $shadow-green;
    filter: blur(214px);
    transform: rotate(-73deg);
    z-index: 0;
  }

  &-coins-bg-left {
    position: absolute;
    top: 50%;
    left: 70px;
    transform: translateY(-50%);
    z-index: 1;
  }

  &-coins-bg-right {
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translateY(-50%);
    z-index: 1;
  }

  @include mq($tablet) {
    &-coins-bg-left {
      left: 0px;
    }

    &-coins-bg-right {
      right: 0px;
    }
  }

  &-coins-bg-left-mobile {
    position: absolute;
    top: 10%;
    left: 0;
    transform: translateY(-10%);
    z-index: 1;

    @include mq($small-mobile) {
      opacity: 0.4;
    }
  }

  &-coins-bg-right-mobile {
    position: absolute;
    top: 30%;
    right: 0;
    transform: translateY(-30%);
    z-index: 1;

    @include mq($small-mobile) {
      opacity: 0.3;
    }
  }

  &-container {
    @include flex-row(center);
    height: 100%;

    .hero-content {
      @include flex-column(center, center);
    }

    .mock-btn {
      height: 56px;
    }

    .header-box {
      position: relative;
      @include flex-column(center, center);

      .header,
      .subheader {
        position: relative;
        text-align: center;
        z-index: 10;
      }

      .subheader {
        transform: translateX(-10px);

        @include mq($mobile) {
          max-width: 188px;
          text-align: center;
          margin-top: 23px;
          transform: translateX(0px);
        }
      }

      .header-blur {
        @include absolute-center;
        width: 445px;
        height: 180px;
        background: $shadow-violet;
        filter: blur(214px);
        z-index: 0;
      }
    }

    .buttons-box {
      margin-top: 77px;
      width: 100%;
      max-width: 410px;
      z-index: 10;

      .button-link {
        width: 100%;
        margin-bottom: 35px;
      }

      @include mq($mobile) {
        margin-top: 35px;
        max-width: 300px;

        .button-link {
          margin-bottom: 25px;
        }
      }
    }
  }

  &-partnerships {
    position: absolute;
    bottom: 1%;
    z-index: 10;
    width: 100%;

    @include mq($mobile) {
      bottom: 10%;
    }
  }
}
