@use "./src/scss/global.scss" as *;

.mobile-menu {
  background-color: $dark-purple3;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index301;
  padding: 23px 20px;
  @include flex-column(center, flex-start);

  .menu-header {
    width: 100%;
    @include flex-row(space-between);
  }

  .pancake-btn {
    text-transform: uppercase;
    margin-top: 45px;
  }

  .mobile-menu-social-bar {
    padding-top: 110px;
    max-width: 240px;
  }
}
