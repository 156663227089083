@use "./mixins.scss" as *;
@use "./variables.scss" as *;

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
}

h1 {
  @include text-scaffold(44px, $bold, $green, 42px, 0);
}

h2 {
  @include text-scaffold(24px, $medium, $green, 32px, 0.13px);
}

p {
  @include text-scaffold(18px, $regular, $white, 28px, 0);
  @include mq($mobile) {
    font-size: 14px !important;
    line-height: 24px !important;
  }
}

p.s1 {
  @include text-scaffold(32px, $regular, $white, 32px, 0);
  @include mq($mobile) {
    font-size: 24px !important;
    line-height: 24px !important;
  }
}

p.s2 {
  @include text-scaffold(14px, $regular, $white, 24px, 0);
}

p.s3 {
  @include text-scaffold(24px, $medium, $white, 36px, 0);
}
