//colors ============================
$primary: #08baaf;
$primary-hover: #00867e;
$secondary: #9d4fea;
$secondary-hover: #741fc9;
$green: #30d860;
$violet: #c03bff;
$white: #ffffff;
$black: #000000;

$dark-purple1: #1c0a45;
$dark-purple2: #522182;
$dark-purple3: #290856;
$dirty-green: #012e3e;
$indigo: #2e1072;
$purple: #783e99;
$navy: #0e0523;
$dark: #10042b;
//colors =============================

//gradients ========================
$gradient1: linear-gradient(180deg, #11052d 78.94%, #2e1072 100%);
$gradient2: linear-gradient(0deg, #1c0a45 51.65%, #2e1072 100%);
$gradient3: linear-gradient(180deg, #0e0523 41.07%, #1c0a45 83.55%);
//gradients ========================

//shadows ========================
$shadow-sky-blue: radial-gradient(
  50% 50% at 50% 50%,
  rgba(2, 210, 220, 0.8) 2.08%,
  rgba(2, 210, 220, 0.8) 100%
);
$shadow-white: radial-gradient(50% 50% at 50% 50%, #ffffff 2.08%, #f4e4c8 100%);
$shadow-gold: radial-gradient(
  50% 50% at 50% 50%,
  rgba(249, 245, 206, 0.67) 2.08%,
  rgba(214, 180, 143, 0.67) 100%
);
$shadow-violet: radial-gradient(
  50% 50% at 50% 50%,
  rgba(183, 111, 255, 0.8) 2.08%,
  rgba(183, 111, 255, 0.8) 100%
);
$shadow-purple1: radial-gradient(
  50% 50% at 50% 50%,
  rgba(128, 0, 255, 0.6) 2.08%,
  rgba(143, 32, 255, 0.6) 100%
);
$shadow-purple2: radial-gradient(50% 50% at 50% 50%, #8000ff 2.08%, #8f20ff 100%);
$shadow-green: radial-gradient(50% 50% at 50% 50%, #30d860 2.08%, #30d860 100%);
$shadow-dark: 7.22762px 7.58308px 13.4688px rgba(0, 0, 0, 0.15);
//shadows ========================

//brakpoints =========================
$small-mobile: 375px;
$mobile: 768px;
$tablet: 991px;
$desktop: 1200px;
//brakpoints =========================

//font-weights ========================
$regular: 400;
$medium: 700;
$bold: 900;
//font-weights ========================
