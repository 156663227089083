@use "./src/scss/global.scss" as *;

.roadmap-item {
  @include flex-row(flex-end);
  width: 100%;
  height: max-content;
  align-items: flex-start;
  column-gap: 45px;
  box-sizing: content-box;
  transform-origin: right;
  will-change: transform, opacity;

  &-text {
    text-align: right;
  }

  &-icon {
    padding-top: 6px;
  }

  @include mq($mobile) {
    column-gap: 22px;

    &-icon {
      padding-top: 0px;
    }
  }
}
