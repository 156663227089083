@use "./src/scss/global.scss" as *;

.menu-item {
  @include text-scaffold(14px, $regular, $white, 24px, 0);
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translate(-50%);
    height: 1.5px;
    width: 0px;
    background: transparent;
    transition: width 0.3s ease, background-color 0.3s ease;
  }

  &:hover {
    color: $secondary-hover;
    transition: color 0.3s ease;
  }

  &:hover::after {
    width: 70%;
    background: $secondary-hover;
  }
}

.menu-item-scrolled {
  color: $black;

  &:hover {
    color: $secondary-hover;
  }
  &:hover::after {
    background: $secondary-hover;
  }
}

.menu-item-active {
  &:before {
    content: "";
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translate(-50%);
    width: 70%;
    height: 1.5px;
    background: $green;
  }
}

.menu-item-active-scrolled {
  &:before {
    content: "";
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translate(-50%);
    width: 70%;
    height: 1.5px;
    background: $black;
  }
}

.menu-mobile-item {
  @include text-scaffold(24px, $regular, $white, 24px, 0);
  cursor: pointer;
  text-align: center;
}

.menu-mobile-item-active {
  color: $green;
}
