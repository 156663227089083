@use "./src/scss/global.scss" as *;

.movable-image-container {
  height: max-content;
  width: max-content;

  .movable-image {
    will-change: transform;
    transition: transform 0.5s;
  }
}
