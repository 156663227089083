@use "./src/scss/global.scss" as *;

.token-details {
  padding-top: 187px;
  padding-bottom: 184px;
  background: $gradient2;
  overflow-x: clip;

  @include mq($mobile) {
    padding-top: 147px;
    padding-bottom: 185px;
  }

  .face-image {
    position: relative;
    z-index: 1;
  }

  .left-face-anim-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .right-face-anim-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  .left-face-box {
    position: absolute;
    top: 50%;
    left: -312px;
    transform: translateY(-50%);

    .left-face-blur {
      @include absolute-center;
      width: 447px;
      height: 162px;
      background: $shadow-green;
      filter: blur(131px);
    }
  }

  .right-face-box {
    position: absolute;
    top: 50%;
    right: -295px;
    transform: translateY(-50%);

    .right-face-blur {
      @include absolute-center;
      width: 331px;
      height: 134px;
      background: $shadow-purple2;
      filter: blur(159px);
    }
  }

  .token-details-content {
    position: relative;
    z-index: 1;
    @include flex-column(center, center);

    .hero-box {
      position: relative;

      .hero-image {
        position: relative;
        z-index: 1;
      }

      .hero-blur {
        @include absolute-center;
        width: 235.46px;
        height: 122.62px;
        background: $shadow-gold;
        filter: blur(90px);
      }
    }

    .token-details-text {
      padding-top: 40px;
    }

    .token-contract-adress {
      padding-top: 20px;
    }

    .token-details-button {
      margin-top: 40px;
    }

    @include mq($mobile) {
      .token-details-text {
        padding-top: 54px;
      }

      .token-contract-adress {
        padding-top: 30px;
      }

      .token-details-button {
        margin-top: 54px;
      }
    }

    @include mq($small-mobile) {
      .token-contract-adress span {
        font-size: 12px;
      }
    }
  }
}
