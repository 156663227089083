@use "./src/scss/global.scss" as *;

.use-cases {
  padding-top: 160px;
  padding-bottom: 80px;
  background: $navy;
  overflow: hidden;

  @include mq($mobile) {
    padding-top: 86px;
    padding-bottom: 225px;
  }

  .use-cases-cards {
    @include flex-row(center);
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 50px 30px;

    .use-cases-card {
      max-width: 350px;
    }

    @include mq($mobile) {
      .use-cases-card {
        max-width: 260px;
      }
    }
  }
}
