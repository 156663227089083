@use "./src/scss/global.scss" as *;

.litepaper {
  overflow-x: clip;
  background-color: $purple;
  padding: 0;

  &-outline {
    height: 130px;
    width: 100%;
    background-color: $navy;
  }

  &-container {
    @include flex-row(flex-start);
    padding: 0 12px;
  }

  &-image-box {
    position: relative;
    min-height: 320px;
    flex-basis: 50%;

    .litepaper-image {
      position: absolute;
      left: 110px;
      bottom: 38px;
      z-index: 3;
    }

    .litepaper-blur {
      position: absolute;
      width: 410px;
      height: 251px;
      left: 110px;
      bottom: 110px;
      background: $shadow-purple1;
      filter: blur(133px);
      z-index: 2;
    }

    .litepaper-shadow {
      position: absolute;
      left: 10px;
      bottom: 1px;
      z-index: 1;
      filter: blur(21px);
    }
  }

  &-content {
    flex-basis: 50%;
    position: relative;
    z-index: 4;

    .text {
      padding-top: 24px;
    }

    .litepaper-button {
      margin-top: 51px;
    }
  }

  @include mq($tablet) {
    &-image-box {
      .litepaper-image {
        left: 0px;
      }

      .litepaper-blur {
        left: 0px;
      }

      .litepaper-shadow {
        left: -100px;
      }
    }
  }

  @include mq($mobile) {
    padding-top: 55px;
    padding-bottom: 120px;

    &-container {
      @include flex-column(center, flex-start);
    }

    &-content {
      .haeder,
      .text {
        max-width: 241px;
        position: relative;
        z-index: 4;
      }

      .text {
        padding-top: 21px;
      }

      .litepaper-button {
        margin: 51px auto 0;
      }
    }

    &-image-box {
      margin-top: 25px;

      .litepaper-image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
      .litepaper-blur {
        bottom: 70px;
      }

      .litepaper-shadow {
        left: -80px;
        bottom: -30px;
        filter: blur(11px);
      }
    }
  }
}
