@use "./src/scss/global.scss" as *;

.blockchainInfo {
  background-color: $dark-purple1;
  background-image: url("../../../../public/images/png/blockchain-info-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  overflow-x: clip;

  &-content {
    padding-top: 183px;
    padding-bottom: 352px;
    padding-left: 40px;
    position: relative;
    width: max-content;
    z-index: 10;
    .header {
      max-width: 440px;
    }

    .description {
      max-width: 360px;
      padding-top: 48px;
    }
  }

  @include mq($mobile) {
    background-image: none;

    &-content {
      padding-top: 100px;
      padding-bottom: 435px;
      padding-left: 0;
      margin: auto;
      max-width: 300px;
    }

    .description {
      max-width: 170px;
      padding-top: 40px;
    }
  }

  &-coin-desktop-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    .coin {
      position: absolute;
      right: 20px;
      top: 366px;
      z-index: 4;
    }

    .coin-blur {
      position: absolute;
      width: 415px;
      height: 222px;
      right: -40px;
      top: 450px;
      background: $shadow-white;
      opacity: 0.43;
      filter: blur(159px);
      transform: rotate(-53deg);
      z-index: 2;
    }

    .blue-arrow,
    .cyan-arrow,
    .purple-arrow,
    .green-arrow,
    .white-arrow,
    .green-arrow-bottom {
      z-index: 3;
    }

    .green-arrow-bottom {
      overflow: visible !important;
    }

    .blue-arrow {
      position: absolute;
      width: 347px;
      height: 114px;
      top: 250px;
      right: -310px;
    }

    .cyan-arrow {
      position: absolute;
      top: 160px;
      right: 80px;
    }

    .purple-arrow {
      position: absolute;
      top: 120px;
      right: 280px;
    }

    .green-arrow {
      position: absolute;
      top: 320px;
      right: 350px;
    }

    .white-arrow {
      position: absolute;
      top: 560px;
      right: 300px;
    }

    .green-arrow-bottom {
      position: absolute;
      top: 700px;
      right: -220px;
    }
  }

  &-coin-mobile-wrapper {
    position: relative;

    .coin {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100px;
      z-index: 3;
    }

    .coin-blur {
      position: absolute;
      width: 360px;
      height: 192px;
      left: 30%;
      transform: translateX(-30%);
      bottom: 100px;
      background: $shadow-white;
      opacity: 0.43;
      filter: blur(138px);
      transform: rotate(-53deg);
      z-index: 1;
    }

    .blue-arrow,
    .green-arrow,
    .white-arrow,
    .cyan-arrow {
      z-index: 2;
    }

    .blue-arrow {
      position: absolute;
      left: calc(50% + 100px);
      bottom: 350px;
    }

    .green-arrow {
      position: absolute;
      left: calc(50% - 430px);
      bottom: 300px;
    }

    .white-arrow {
      position: absolute;
      left: calc(50% - 450px);
      bottom: 120px;
    }

    .cyan-arrow {
      position: absolute;
      left: calc(50% + 60px);
      bottom: 0;
    }
  }
}
