@use "./src/scss/global.scss" as *;

.buff-tooltip {
  position: absolute;
  top: 60%;
  width: max-content;
  display: flex;
  flex-direction: column;
  background: $dark-purple1;
  border-radius: 10px;
  padding: 10px 20px;

  &.left {
    right: 60%;
  }

  &.right {
    left: 60%;
  }
}
