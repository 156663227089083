/*
  Default value of z-index for section is between 0 - 10.
  For buff-video and footer sections, initial value of z-index is 200 so that sticky social bar is below them.
  
  Z-index values: 
   - 100 - sticky social media bar
   - 200 - buff-video and footer section 
   - 300 - menu desktop and mobile hamburger button
   - 301 - mobile menu
*/

$z-index100: 100;
$z-index200: 200;
$z-index300: 300;
$z-index301: 301;
