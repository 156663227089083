@use "./src/scss/global.scss" as *;

.pancake-swap {
  height: 100vh;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-content {
    @include flex-column(center, center);

    .hero-box {
      position: relative;

      .hero-image {
        position: relative;
        z-index: 1;
      }

      .hero-blur {
        @include absolute-center;
        width: 226px;
        height: 147px;
        background: $shadow-sky-blue;
        filter: blur(72.2359px);
      }
    }

    .header {
      color: $white;
      padding-top: 47px;
    }

    .description {
      max-width: 588px;
      text-align: center;
      padding-top: 14px;
    }

    @include mq($mobile) {
      .description {
        max-width: 267px;
      }
    }

    .pancake-swap-buttons {
      padding-top: 40px;
      max-width: 900px;
      width: 100%;
      @include flex-row(space-between);

      .pancake-swap-btn {
        width: 260px;
      }

      @include mq($tablet) {
        @include flex-column(center, center);
        gap: 22px 0;
        padding-top: 14px;

        .pancake-swap-btn {
          width: 270px;
        }
      }
    }
  }
}
