@use "./src/scss/global.scss" as *;

.social-media-bar {
  height: max-content;
  width: 100%;
  @include flex-row(space-between);
}

.sticky-social-media-bar {
  @include flex-column(flex-start, flex-start);
  position: fixed;
  top: 289px;
  right: calc(50% - 540px);
  gap: 10px;
  z-index: $z-index100;

  @include mq(1100px) {
    right: 10px;
  }
}
